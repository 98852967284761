@use "../../partials/variables" as *;
@use "../../partials/mixins" as *;

.about {
    padding: 1rem;
    text-align: center;

    
    &__me {
        @include tablet {
            width: 75%;
            margin: 0 auto;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: center;

            & p {
                margin-bottom: 2rem;
                width: 50%;
            }
        }
    }

    &__languages {
        @include tablet {
            width: 75%;
            margin: 0 auto;
        }

        @include desktop {
            width: 40%;
        }
        
        &-icons{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        &-icon{
            width: 50px;
            margin-right: 1rem;
            margin-bottom: 1rem;

            @include tablet {
                width: 75px;
            }
        }
    }
}
