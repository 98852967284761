@use "../..//partials/mixins" as *;
@use "../..//partials/variables" as *;

.contact {
    padding: 1rem;

    @include desktop {
        padding: 1rem 4rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;

        @include tablet {
            flex-direction: row;
            justify-content: center;
            height: 40rem;
            margin-top: 0;
        }

        &-image {
            width: 10rem;
            border-radius: 50%;

            @include tablet {
                width: 15rem;
                order: 3;
                animation-delay: 1.2s;
            }

            @include desktop {
                width: 18.5rem;
            }
        }

        &-divider {
            height: 1px;
            width: 100%;
            background-color: rgba($color: $primary-dark, $alpha: 0.4);
            margin: 2rem 0;

            @include tablet {
                height: 20rem;
                width: 1px;
                margin: 0 2rem;
                order: 2;
            }
        }

        &-text {
            display: flex;
            flex-direction: column;

            &-icon {
                width: 50px;
                margin-right: 3rem;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }

                &--linkedin {
                    animation: bounceText 0.2s linear;
                    animation-delay: 0ms;
                }

                &--github {
                    animation: bounceText 0.2s linear;
                    animation-delay: 0.2s;
                }

                &--email {
                    animation: bounceText 0.2s linear;
                    animation-delay: 0.4s;
                }
            }

            & div {
                display: flex;
                align-items: center;
            }

            &-title {
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }

                &--linkedin {
                    animation: bounceText 0.2s linear;
                    animation-delay: 0.6s;
                }

                &--github {
                    animation: bounceText 0.2s linear;
                    animation-delay: 0.8s;
                }

                &--email {
                    animation: bounceText 0.2s linear;
                    animation-delay: 1s;
                }
            }
        }
    }
}

@keyframes bounceText {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1.1);
    }
}
