@use './partials/fonts';

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
}

body {
    max-width: 1662px;
    margin: 0 auto;
}
.App{
    overflow: hidden;
}