@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

.bar1,
.bar2 {
    width: 35px;
    height: 3px;
    background-color: $primary-dark;
    margin: 6px 0;

    @include tablet{
        display: none;
    }
}

.nav {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 1rem;
    height: 8.5%;

    @include tablet{
        align-items: center;
    }

    &--closed{
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        top: 0;
        left: 100vw;
        width: 100%;
        height: 100vh;
        padding-top: 1rem;
        transition: all .5s ease-in-out;
        background-color: $primary-light;
        z-index: 1;

        @include tablet{
            position: static;
            background: none;
            flex-direction: row;
            justify-content: space-evenly;
            height: fit-content;
            padding: 0;
            transition: none;
        }
    }

    &--open{
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding-top: 1rem;
        transition: all .5s ease-in-out;
        z-index: 1;
    }

    &__close{
        align-self: flex-end;
        margin-right: 1rem;
        font-size: 1.5rem;
        cursor: pointer;

        @include tablet{
            display: none;
        }
    }

    &__link{
        padding: 1rem 0;
        margin-bottom: 3rem;
        padding-left: 1rem;
        width: 100%;
        text-align: center;
        font-size: 2rem;
        z-index: 1;
        cursor: pointer;

        @include tablet{
            margin: 0;
            padding: 0;
            width: fit-content;

            &:hover{
                transform: scale(1.1);
            }
        }
    }
}
