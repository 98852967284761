@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    @include desktop {
        padding: 1rem 4rem;
    }

    &__independant {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            align-self: flex-start;
        }

    }

    &__collaborative {
        @extend .projects__independant;

        &-title {
            @extend .projects__independant-title;
        }
    }

    &__project {
        &-container {
            margin-bottom: 2rem;
            padding: 0.5rem;
            padding: 0.5rem 1rem;

            @include tablet {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 20rem;
                border-bottom: 1px solid rgba($color: $primary-dark, $alpha: 0.1);
                margin-bottom: 0;
                padding-bottom: 1.5rem;
                margin-bottom: 1rem;
                max-width: 920px;
            }

            & p {
                margin: 0;
                text-align: center;
            }

            &--kbox {
                border-bottom: none;
            }

            &--cg {
                border-bottom: none;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include tablet {
                width: 16rem;
            }
        }

        &-title {
            text-align: center;
            margin: 0;
        }

        &-poster {
            display: none;

            @include tablet {
                display: initial;
                width: 22.5rem;
                mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
                -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);

                &:hover {
                    mask-image: none;
                    -webkit-mask-image: none;
                    transform: scale(1.02);
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }

    &__links {
        display: flex;
        justify-content: space-between;
        width: 8rem;

        @include tablet {
            width: 10rem;
        }

        & a {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            width: fit-content;

            &:hover {
                transform: scale(1.2);
            }
            & span {
                text-align: center;
                font-size: 1rem;
            }
        }

        & img {
            width: 25px;
            margin: 1rem;
            margin-bottom: 0.5rem;

            @include tablet {
                width: 35px;
            }
        }
    }
}
