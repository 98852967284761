@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;

.homepage{
    height: 91.5vh;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &__scroll{
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        height: fit-content;

        &-container{
            display: flex;
            justify-content: center;
        }
        &-arrow{
            width: 3.2rem;
            align-self: flex-end;
            animation: bounce 4s linear infinite ;

            @include tablet{
                width: 3.75rem
            }

            &--reverse{
                @extend .homepage__scroll-arrow;
                transform: rotate(180deg);
                animation: none;
            }
        }
    }

    &__highlight{
        height: 100vh;
        min-height: 750px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;

        &-best{
            display: flex;
            flex-direction: column;
            align-items: center;

            @include tablet{
                height: 100vh;
                min-height: 745px;
                justify-content: space-between;

            }
        }

        &-title{
            text-align: center;
            margin: 0;
            margin-top: 1rem;
        }

        &-subtitle{
            text-align: center;
            margin: 0;
            margin-bottom: 1rem;
        }
        
        &-video{
            width: 12rem;

            @include tablet{
                width: 15rem;
            }

            &-container{
                height: 25rem;
                display: flex;
                align-items: center;
                justify-content: center;

                @include tablet{
                    height: 32rem;
                }
            }

            &--tablet{
                width: 18rem;

                @include tablet{
                    width: 24rem;
                }
            }

            &--desktop{
                width: 24rem;

                @include tablet{
                    width: 40rem;
                }
            }
        }

        &-selectors{
            padding-top: 3rem;
            display: flex;
            justify-content: center;
            width: 100%;
            
            & span {
                cursor: pointer;
                margin: 0 2rem;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
.Typewriter {
    display: flex;
    align-items: center;
    height: 100%;

    &__cursor{
        font-size: 2rem;
        font-weight: 300;

        @include tablet {
            font-size: 3.5rem;
        }
    }
}

@keyframes bounce {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
