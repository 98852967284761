@use './mixins' as *;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

h1 {
    font-family: Quicksand, Arial, sans-serif;

    @include tablet{
        font-size: 4rem;
    }

}
h2 {
    font-family: Quicksand, Arial, sans-serif;

    @include tablet {
        font-size: 2.5rem;
    }
    
}
h3 {
    font-family: Quicksand, Arial, sans-serif;
    
}
h4 {
    font-family: Quicksand, Arial, sans-serif;

    @include tablet{
        font-size: 1.25rem;
    }
    
}
p, a {
    font-family: Quicksand, Arial, sans-serif;
    text-decoration: none;
    color: #000;
    
}
span {
    font-weight: 600;

    @include tablet{
        font-size: 1.25rem;
    }
}